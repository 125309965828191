import React from "react";
import avocadoToast from '../resources/avocadoToast.jpg';

function Stuff() {
  return (
    <div className="stuff">
      <div class="container">
        <div class="row align-items-center my-5">
          <div class="col-lg-7">
            <img
              class="img-fluid rounded mb-4 mb-lg-0"
              src={avocadoToast}
              alt=""
            />
          </div>
          <div class="col-lg-5">
            <h1 class="font-weight-light">Stuff</h1>
            <p>
                And here is where I would put all the stuff. If I had any! Until then, enjoy this image of an avocado toast. Man, I love avocado toast.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Stuff;