import React from 'react';

function Footer() {
  return (
    <div className='footer'>
      <footer class='py-5 bg-dark fixed-bottom'>
        <div class='container'>
          <p class='m-0 text-center text-white'>
            Oh boy, a footer! It looks so official! Especially when I put a
            copyright character here: &copy;
            <div>
              Cute squid icon made by{' '}
              <a
                href='https://www.flaticon.com/authors/adib-sulthon'
                title='Adib Sulthon'
              >
                Adib Sulthon
              </a>{' '}
              from{' '}
              <a href='https://www.flaticon.com/' title='Flaticon'>
                www.flaticon.com
              </a>
            </div>
          </p>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
