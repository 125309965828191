import React from "react";
import happyProgrammer from '../resources/happyProgrammer.jpg';

function About() {
  return (
    <div className="about">
      <div class="container">
        <div class="row align-items-center my-5">
          <div class="col-lg-7">
            <img
              class="img-fluid rounded mb-4 mb-lg-0"
              src={happyProgrammer}
              alt=""
            />
          </div>
          <div class="col-lg-5">
            <h1 class="font-weight-light">About me, I guess</h1>
            <p>
              One fine day, you will find an awkward self description of me on this page. Today is not that day. Until this day comes in the far, far future, enjoy this picture of a programmer pointing on a piece of paper with the word "code". Aww, he looks so happy!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;