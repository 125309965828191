import React from 'react';
import catOnKeyboard from '../resources/catonkeyboard.jpg';

function Home() {
  return (
    <div className='home'>
      <div class='container'>
        <div class='row align-items-center my-5'>
          <div class='col-lg-5'>
            <h1 class='font-weight-light'>Home</h1>
            <p>
              If you can read this, Helen actually managed to deploy a website!
              Whaaaaat?! Crazy!
            </p>
          </div>
          <div class='col-lg-7'>
            <img
              class='img-fluid rounded mb-4 mb-lg-0'
              src={catOnKeyboard}
              alt=''
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
